import React from 'react';
import './index.scss';
import { isMobile } from 'react-device-detect';

class WistiaVideo extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      currentVideo: false,
      playerLoaded: false,
      isMobile: false,
    };
    this.onVideoReady = this.onVideoReady.bind(this);
    this.initTracking = this.initTracking.bind(this);
    this.beforeVideoReplace = this.beforeVideoReplace.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const wistiaLib = document.createElement('script');
    wistiaLib.src = `https://fast.wistia.net/assets/external/E-v1.js`;
    wistiaLib.async = true;
    document.body.appendChild(wistiaLib);
    this.initTracking();
    this.setState({
      isMobile: isMobile,
    });
  }

  onVideoReady(video) {
    if (this._isMounted) {
      this.setState({
        currentVideo: video,
      });
    }
    video.bind('beforereplace', this.beforeVideoReplace);
  }

  componentWillUpdate(nextProps) {
    if (
      nextProps.wistiaID !== this.props.wistiaID &&
      typeof this.state.currentVideo === 'object'
    ) {
      this.replaceWith(this.props.wistiaID, nextProps.wistiaID);
    }
  }

  initTracking() {
    setTimeout(() => {
      window._wq.push({ id: '_all', onReady: this.onVideoReady });
    }, 900);
    if (typeof window.Wistia === `undefined`) {
      return;
    }
    const video = window.Wistia.api(this.props.wistiaID);
    if (typeof video === `undefined`) {
      return null;
    }
  }

  beforeVideoReplace(video) {
  }

  replaceWith(oldVideoID, nextVideoID) {
    if (typeof window.Wistia === `undefined`) {
      return;
    }
    const video = window.Wistia.api(oldVideoID);

    if (typeof video === `undefined`) {
      return;
    }
    video.replaceWith(nextVideoID, { transition: 'crossfade', autoPlay: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { wistiaID, inModal } = this.props;
    const width = this.state.isMobile ? '95vw' : '40vw';

    return (
      <div
        id={`video-container-${wistiaID}`}
        className="animated"
        style={{ width: inModal ? '100%' : width, paddingBottom: '2rem' }}
        >
        <div
          className="wistia_responsive_padding"
          style={{
            padding: '56.25% 0 0 0',
            position: 'relative',
            paddingBottom: '2rem',
          }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          >
            <div
              className={`wistia_embed wistia_async_${wistiaID} videoFoam=true playlistLinks=auto`}
              data-options="doNotTrack=true"
              style={{ height: '100%', position: 'relative', width: '100%' }}
            >
              <div
                className="wistia_swatch"
                style={{
                  height: '100%',
                 

                  left: 0,
                  opacity: 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  top: 0,
                  transition: 'opacity 200ms',
                  width: '100%',
                }}
              >
                <img
                  src={`https://fast.wistia.com/embed/medias/${wistiaID}/swatch`}
                  style={{
                    filter: 'blur(5px)',
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                  alt=""
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WistiaVideo;